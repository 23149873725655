import React, { useEffect } from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";
import image from "./logo.png";
import {
  BrowserRouter as Router,
  Route,
  Link,
  useNavigate,
  useLocation,
} from "react-router-dom";
import { useState, useContext } from "react";
import { Button, Image, Avatar, Dropdown, Select } from "antd";
import { UserContext } from "../utils/Usercontext";
import { Layout, Menu, Breadcrumb } from "antd";
import ReactFlagsSelect from "react-flags-select";
import { Gb } from "react-flags-select";
import "./flag.css";
import { useTranslation } from "react-i18next";
import {
  TeamOutlined,
  DownOutlined,
  UserOutlined,
  AlignCenterOutlined,
  SettingOutlined,
  InfoCircleFilled,
  LogoutOutlined,
  FormOutlined,
  FundOutlined,
  BlockOutlined,
  ExperimentOutlined,
} from "@ant-design/icons";

import "./layoutstyle.css";
const { Header, Content, Footer, Sider } = Layout;

function getItem(label, key, icon, children) {
  return {
    key,
    icon,
    children,
    label,
  };
}

const CustomLayout = (props) => {
  const [selectedKeys, setSelectedKeys] = useState(["1"]);
  const [collapsed, setCollapsed] = useState(false);
  const { isAuth, setisAuth } = useContext(UserContext);
  const [istoken, setToken] = useState(false);

  const { t, i18n } = useTranslation();

  const handleResize = () => {
    if (window.innerWidth < 1300) {
      setCollapsed(true);
    } else {
      setCollapsed(false);
    }
  };

  useEffect(() => {
    handleResize();

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const onChangeLanguage = (selectedLanguage) => {
    i18n.changeLanguage(selectedLanguage);
  };

  const handleMenuClick = ({ key }) => {
    setSelectedKeys([key]);
  };

  const items = [
    getItem(
      <Link to="/cleaning">
        {" "}
        <SettingOutlined /> <span>CleanData</span>{" "}
      </Link>
    ),
    getItem(
      <Link to="/config">
        {" "}
        <SettingOutlined /> <span>{t("configuration")}</span>{" "}
      </Link>
    ),
    getItem(
      <Link to="/kpis">
        {" "}
        <AlignCenterOutlined />
        <span>{t("simulation")}</span>{" "}
      </Link>
    ),
    getItem(
      <Link to="/Optimisations">
        {" "}
        <FundOutlined /> <span>{t("optimisation")}</span>{" "}
      </Link>
    ),
    getItem(
      <Link to="/actions">
        {" "}
        <BlockOutlined /> <span>{t("action")}</span>{" "}
      </Link>
    ),
    getItem(
      <Link to="/practices">
        {" "}
        <ExperimentOutlined /> <span>{t("practice")}</span>{" "}
      </Link>
    ),
    getItem(
      <Link to="/planning">
        {" "}
        <FormOutlined /> <span>{t("planning")}</span>{" "}
      </Link>
    ),
    getItem(t("team"), "RDIUP", <TeamOutlined />, [
      getItem("Habib"),
      getItem("Dah"),
      getItem("Robert"),
      getItem("Aziz"),
    ]),
  ];

  const navigate = useNavigate();
  const location = useLocation();

  const logout = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("user");
    navigate("/login");
    setisAuth(false);
  };

  const itemsProfile = [
    /*  {
      key: "profile",
      label: t("Profile"),
      icon: <UserOutlined />,
    }, */
    {
      key: "settings",
      label: t("Account_settings"),
      icon: <SettingOutlined />,
    },
    {
      key: "privacypolicy",
      label: t("Privacy_policy"),
      icon: <InfoCircleFilled />,
    },
    {
      key: "signout",
      label: t("logout"),
      icon: <LogoutOutlined style={{ marginTop: 20 }} />,
    },
  ];

  const profileMenu = (
    <Menu onClick={logounst}>
      {itemsProfile.map((itemP) => (
        <Menu.Item key={itemP.key}>
          <a target="_blank" rel="noopener noreferrer">
            {itemP.icon} {itemP.label}
          </a>
        </Menu.Item>
      ))}
    </Menu>
  );

  function logounst(e) {
    if (e.key === "signout") {
      // Perform logout action here, such as clearing authentication state and redirecting to login page
      logout();
    }
  }

  return (
    <Layout>
      <Header
        style={{
          top: 0,
          position: "fixed",
          zIndex: 1,
          width: "100%",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          boxShadow: "0 2px 4px rgba(0, 0, 0, 0.5)",
          // overflowX: 'auto',
          whiteSpace: "nowrap",
          // overflowY: 'hidden',
        }}
      >
        {/* Left part: logo and menu */}
        <div style={{ display: "flex", alignItems: "center" }}>
          <div className="demo-logo">
            <Image src={image} width={100} preview={false} />
          </div>
          <Menu theme="dark" mode="horizontal" defaultSelectedKeys={["3"]}>
            {isAuth ? (
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "center",
                  marginLeft: "10px",
                }}
              >
                <Menu.Item
                  key="/scenarios"
                  style={{
                    backgroundColor:
                      location.pathname === "/scenarios"
                        ? "#1b93fb"
                        : "transparent",
                    width: 120,
                  }}
                >
                  <Link to="/scenarios">{t("scenarios")}</Link>
                </Menu.Item>
                <Menu.Item
                  key="/settings"
                  style={{
                    backgroundColor:
                      location.pathname === "/settings"
                        ? "#1b93fb"
                        : "transparent",
                    width: 120,
                  }}
                >
                  <Link to="/settings">{t("setting")}</Link>
                </Menu.Item>
              </div>
            ) : (
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "center",
                  marginLeft: "10px",
                }}
              >
                <Menu.Item
                  key="/"
                  style={{
                    backgroundColor:
                      location.pathname === "/" ? "#1b93fb" : "transparent",
                  }}
                >
                  <Link to="/">{t("home")}</Link>
                </Menu.Item>
                <Menu.Item
                  key="/contact"
                  style={{
                    backgroundColor:
                      location.pathname === "/contact"
                        ? "#1b93fb"
                        : "transparent",
                  }}
                >
                  <Link to="/contact">Contact</Link>
                </Menu.Item>
              </div>
            )}
          </Menu>
        </div>

        {/* Right part: profile and flags */}
        <div
          style={{
            display: "flex",
            alignItems: "center",
            position: "relative",
          }}
        >
          {isAuth ? (
            <Dropdown overlay={profileMenu} placement="bottomCenter" arrow>
              <Button
                style={{
                  borderRadius: 20,
                  fontFamily: "Poppins",
                  fontWeight: "600",
                  marginRight: 20,
                }}
              >
                <Avatar
                  style={{
                    backgroundColor: "#1b93fb",
                    marginTop: -5,
                    left: -15,
                  }}
                  icon={<UserOutlined />}
                />
                {localStorage.getItem("user")} <DownOutlined />
              </Button>
            </Dropdown>
          ) : (
            <>
              <Button type="primary" onClick={() => navigate("/login")}>
                {t("login")}
              </Button>
              <Button
                style={{ marginLeft: 10 }}
                onClick={() => navigate("/register")}
              >
                {t("register")}
              </Button>
            </>
          )}
          <div style={{ zIndex: 3000, position: "relative" }}>
            <ReactFlagsSelect
              className="custom-flags-select"
              selectButtonClassName="menu-flags-button"
              fullWidth={false}
              selected={i18n.language}
              onSelect={onChangeLanguage}
              countries={["GB", "FR", "IT", "DK", "TR", "ES", "SE"]}
              customLabels={{
                GB: "EN",
                FR: "FR",
                IT: "IT",
                DK: "DK",
                TR: "TR",
                ES: "ES",
                SE: "SE",
              }}
              placeholder={
                <div className="placeholder">
                  <div className="d-flex">
                    <Gb />
                    <span>EN</span>
                  </div>
                </div>
              }
            />
          </div>
        </div>
      </Header>

      {/* Sider part */}

      <Sider
        collapsible
        collapsed={collapsed}
        onCollapse={() => setCollapsed(!collapsed)}
        style={{
          position: "fixed", // Fix the position of the sidebar
          left: 0,
          top: 64, // Offset from the top (header height)
          bottom: 0, // Ensures the sidebar stretches to the bottom
          height: "100vh", // Full viewport height
          overflow: "hidden", // Prevent sidebar scrolling
          boxShadow: "0 2px 4px rgba(0, 0, 0, 0.5)",
          zIndex: 1000, // Keep it above other content
        }}
      >
        <Menu
          theme="light"
          defaultSelectedKeys={["1"]}
          mode="inline"
          items={items}
        />
      </Sider>
      <Layout
        className="site-layout"
        style={{ marginLeft: collapsed ? 80 : 200 }}
      >
        {" "}
        {/* Adjust based on sidebar collapse */}
        <Content
          style={{
            padding: "0 24px",
            marginBottom: "0px",
            marginTop: 50,
            overflowY: "auto",
            minHeight: "100vh",
          }}
        >
          <div style={{ padding: 24, minHeight: 280 }}>
            <Breadcrumb style={{ margin: "5px 0" }}>
              <Breadcrumb.Item>{localStorage.getItem("user")}</Breadcrumb.Item>
              {isAuth ? <Link to="/config">{t("back")}</Link> : ""}
            </Breadcrumb>

            <div className="site-layout-background">
              <HelmetProvider>
                <div>
                  <Helmet>
                    <meta charSet="utf-8" />
                    <title> Smart Planning Tool</title>
                    <link rel="canonical" href="http://mysite.com/example" />
                    <meta name="description" content="Helmet application" />
                  </Helmet>
                </div>
              </HelmetProvider>
              {props.children}
            </div>
          </div>
        </Content>
        <Footer style={{ textAlign: "center", backgroundColor: "white" }}>
          <div className="footer-content">
            <br></br>

            <div
              style={{
                display: "flex",
                justifyContent: "center",
                marginRight: "10px",
              }}
            >
              <Image src={require(".//flagg.png")} width={60} preview={false} />
              <a
                href="https://www.rdiup.com"
                target="_blank"
                rel="noopener noreferrer"
              >
                <Image
                  src={image}
                  width={100}
                  preview={false}
                  style={{ marginLeft: 15, marginTop: 0 }}
                />
              </a>
            </div>
            <br />
            <div
              style={{
                marginLeft: "10px",
                justifyContent: "center",
                display: "flex",
              }}
            >
              <p>
                ©2021-2026 Smart Planning Tool V4 received funding from the
                European Union’s Horizon Europe research and innovation
                programme under grant agreements No 957852 and 101096836.
              </p>
            </div>
          </div>
        </Footer>
      </Layout>
    </Layout>
  );
};

export default CustomLayout;
