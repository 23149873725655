import React, { useState, useEffect, useRef, createRef } from "react";
import {
  Select,
  Modal,
  Col,
  Row,
  Card,
  Typography,
  Spin,
  Divider,
  Checkbox,
  Button,
  DatePicker,
  message,
  Form,
  Input,
  ConfigProvider,
  Radio,
  Dropdown,
  Menu,
  Progress,
} from "antd";
import axios from "axios";
//import { FileDrop } from 'react-file-drop';
import randomColor from "randomcolor";
import Papa from "papaparse";
import {
  PlaySquareOutlined,
  DiffTwoTone,
  HomeTwoTone,
  CalendarTwoTone,
  ThunderboltTwoTone,
  InteractionTwoTone,
  EuroTwoTone,
  FundTwoTone,
  VerticalAlignBottomOutlined,
  InfoCircleTwoTone,
  YoutubeOutlined,
  ControlTwoTone,
  CloudTwoTone,
} from "@ant-design/icons";
import plotButtonImageO from "./optimisation.png";

import { Space, Table, Tag } from "antd";
import { w3cwebsocket as W3CWebSocket } from "websocket";

import "chartjs-plugin-zoom";
import "./chartjs-plugin-zoom-custom.css";

import { CategoryScale, LinearScale, BarElement } from "chart.js";
import { Chart, registerables } from "chart.js/auto";

import { Line } from "react-chartjs-2";
import FootprintIcon from "./Footprinticon";
import { Navigate } from "react-router-dom";
import zoomPlugin from "chartjs-plugin-zoom";
//import LineChart from './chartline.js'
import ResetZoomButton from "./ResetZoomButton";
import { axiosApiInstance } from "../services/axiosAPI";
import "./App.css";

Chart.register(CategoryScale, LinearScale, BarElement);
Chart.register(zoomPlugin);
const { Option } = Select;

function getItem(label, key, icon, children) {
  return {
    key,
    icon,
    children,
    label,
  };
}
Chart.register(CategoryScale, LinearScale, BarElement);
const styleD = {
  background: "#0092ff",
  padding: "8px 0",
};
const { Title, Text } = Typography;
const zoomOptions = {
  zoom: {
    wheel: {
      enabled: true,
    },
    pinch: {
      enabled: true,
    },
    mode: "x",
    speed: 2, //100
  },
  pan: {
    enabled: true,
    mode: "y",
    speed: 2, //0.5
  },
};

class OptimizationForm extends React.Component {
  constructor(props) {
    super(props);
    this.chartRef = React.createRef();

    this.state = {
      selectedConfiguration: null,
      selectedOPT: null,

      configurations: [],
      resultsO: [],
      resultsR: [],
      kpi: [],
      BUILDR: [],
      SELF: [],
      NAMES: [],
      plotRT: [],
      CSV: [],
      csvArray: [],
      i: 0,
      z: 0,
      optID: "",
      selectedtypeO: "",
      itemo: 0,
      itemro: 0,
      itemob: 0,
      selectedOption: "hourly",
      selectedOption2: "daily",
      isComponentMounted: false,
      Dynamics: false,
      Usage: false,
      Efficiency: false,
      Cashflow: false,
      NPV: false,
      SoC: false,
      showSpin: false,
      fNPV: false,
      IRRR: "0.0",
      SAVR: "0.0",
      LCOER: "0.0",
      Foot: "0",
      SystemDelivered: 0,
      PAYR: "0.0",
      ABATR: "0.0",
      DECARBR: "0.0",
      RETR: "0.0",
      verrou: false,
      isCheckedfS: false,
      isCheckedfD: false,
      isCheckedRet: false,
      isCheckedP: false,
      isCheckedIrr: false,
      isCheckedSelf: false,
      isCheckedSC: true,
      isCheckedT: false,
      visible: false,
      selectedCheckboxes: [],
      optimization: null,
      options: null,
      chartVisible: false,
      length: 0,
      SelectedType: "",
      SType: "",
      isPopupVisible: false,
    };
    this.popupRef = createRef();
    this.formRef = React.createRef();

    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    /* const connectPromise = new Promise((resolve) => {
      // Open a WebSocket connection
      this.socket = new WebSocket('ws://127.0.0.1:8000/app/ws/');
      
      // WebSocket onopen event
      this.socket.onopen = () => {
        console.log('WebSocket connection opened');
        resolve();
      };
   
      // WebSocket onclose event
  
    }); */
    document.addEventListener("mousedown", this.handleClickOutside);

    // Wait for the WebSocket connection to open
    //await connectPromise;
    // const configurationID =this.props.match.params.configurationID; // ca bloque
    axiosApiInstance
      .get(`api/v1/?username=${localStorage.getItem("user")}`)
      .then((ress) => {
        this.setState({
          configurations: ress.data,
        });
        console.log(ress.data);
        //console.log(res);
        //console.log(this.state.simulations[2]['simulationID'])
      });

    axiosApiInstance
      .get(`api/v1/resO/?username=${localStorage.getItem("user")}`)
      .then((res) => {
        this.setState({ resultsO: res.data, length: res.data.length }); //console.log("result", this.state.resultsO);
      })
      .catch((error) => {
        console.log("ERROR::: ", error.res);
      });

    this.initializeChartB();

    console.log("i am not created");
  }
  deferChartInitialization = () => {
    requestAnimationFrame(() => {
      this.initializeChartB();
    });
  };

  /*   componentDidUpdate(prevProps, prevState) {
    // Check if showSpin has changed from false to true
    if (!prevState.showSpin && this.state.showSpin) {
      // Call handleSimulation when showSpin becomes true
      message.error('Please wait for the optimisation finishing');

      this.handleSimulation();
    }
    
  }  */

  componentWillUnmount() {
    // this.state.isComponentMounted = false;
    // Close the WebSocket connection when the component is unmounted
    // this.socket.close();
    //document.removeEventListener("mousedown", this.handleClickOutside);
    document.removeEventListener("mousedown", this.handleClickOutside);
    this.setState = (state, callback) => {
      return;
    };
  }

  handleChangeDWM = (value) => {
    this.setState({ selectedOption: value });
    console.log("value", value);
    this.handleClickBDWM(value);
  };

  handleChangeDWMU = (value) => {
    this.setState({ selectedOption2: value });
    console.log("value", value);
    this.handleClickBDWM(value);
  };

  handleIconClick = () => {
    this.setState({ isPopupVisible: true });
  };

  handleClickOutside = (event) => {
    if (
      this.popupRef.current &&
      !this.popupRef.current.contains(event.target)
    ) {
      this.setState({ isPopupVisible: false });
    }
  };

  handleModalOpen = () => {
    console.log("Modal open clicked");

    this.setState({ visible: true });
  };

  handleModalClose = () => {
    console.log("Modal ok clicked");

    this.setState({ visible: false });
  };
  handleModalCancel = () => {
    console.log("Modal Cancel clicked");

    this.setState({ visible: false });
  };

  resetZoom = () => {
    if (this.chart) {
      this.chart.resetZoom();
    }
  };

  handleClickBDWM = async (item) => {
    console.log("e", item.value);

    //this.state.optimization = item;
    this.state.options = item;

    console.log("opitmisa ID", this.state.resultsR["optimizationID"]);
    ///const file = this.state.resultsR["outputCSV"] //'C:\\Users\\habib\\Desktop\\VPP4ISLANDS\\SPT\\SPT V3\\SPT\\frontend\\gui\\src\\components\\Sim_outputs\\sur_plus106.csv';
    //const reader = new FileReader();
    const filepath = `https://sptapp-files.s3.eu-west-3.amazonaws.com/${localStorage.getItem(
      "user"
    )}/opt/${item}${this.state.resultsR["optimizationID"]}.csv`;
    //const file = await require(`./Sim_outputs/${item}${this.state.resultsR["simulationID"]}.csv`);
    console.log("const filepath", filepath);
    const rawdata = await fetch(filepath).then((response) => {
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      return response.text();
    });
    console.log("i am a file", rawdata);
    const data = Papa.parse(rawdata);
    //reader.onload = (file) => {
    this.plotChart(data, item);
    //};
    // reader.readAsText(file);
    this.setState({ chartVisible: true });
  };

  handleClickB = async (item) => {
    console.log("e", item.value);
    this.state.options = null;
    this.state.optimization = item;
    console.log("opitmisa ID", this.state.resultsR["optimizationID"], item);
    ///const file = this.state.resultsR["outputCSV"] //'C:\\Users\\habib\\Desktop\\VPP4ISLANDS\\SPT\\SPT V3\\SPT\\frontend\\gui\\src\\components\\Sim_outputs\\sur_plus106.csv';
    //const reader = new FileReader();
    const filepath = `https://sptapp-files.s3.eu-west-3.amazonaws.com/${localStorage.getItem(
      "user"
    )}/opt/${item}O${this.state.resultsR["optimizationID"]}.csv`;
    //const file = await require(`./Sim_outputs/${item}${this.state.resultsR["simulationID"]}.csv`);
    console.log("const filepath", filepath);
    const rawdata = await fetch(filepath).then((response) => {
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      return response.text();
    });
    console.log("i am a file", rawdata);
    const data = Papa.parse(rawdata);
    //reader.onload = (file) => {
    this.plotChart(data, item);
    //};
    // reader.readAsText(file);
    this.setState({ chartVisible: true });
  };

  async getResults() {
    if (this.state.showSpin === true) {
      console.log("why i am here");
      await new Promise((resolve, reject) => {
        const timer = setInterval(async () => {
          console.log("interval");
          try {
            const res = await axiosApiInstance.get(
              `api/v1/resO/?username=${localStorage.getItem("user")}`
            );
            if (this.state.length < res.data.length) {
              this.setState({
                resultsO: res.data,
                length: res.data.length,
                showSpin: false,
              });
              console.log("result", this.state.resultsO["0"], res.data.length);
              clearInterval(timer);
              resolve();
            }
          } catch (error) {
            console.log("ERROR::: ", error.response);
            clearInterval(timer);
            reject(error);
          }
        }, 20000);
      });
    }
  }

  handleSimulation = async () => {
    // Start loading indicator

    // this.socket = new WebSocket('wss://www.sptapp.eu/app/ws/');//to be commented

    // Wait for the simulation to finish
    // this.socket = new WebSocket('wss://www.sptapp.eu/app/ws/')
    // Create a promise to wait for the "stop_loading" message
    const simulationFinishedPromise = new Promise((resolve) => {
      // WebSocket onmessage event
      this.socket.onmessage = (event) => {
        const data = JSON.parse(event.data);

        if (data.type === "stop_loading") {
          // Stop loading indicator
          this.setState({ showSpin: false });
          console.log("status spining", this.state.showSpin);

          // Resolve the promise when "stop_loading" is received
          resolve();
        }
        // Handle other message types if needed
      };
    });

    // Wait for the "stop_loading" message
    await simulationFinishedPromise;

    /*  await axios.get('https://www.sptapp.eu/api/v1/resO/')
 .then (res =>{
  this.setState({ resultsO: res.data}); console.log("result MESSAGE", this.state.resultsO["0"]); 
  })
  .catch(error => {
    console.log("ERROR::: ",error.res);}) */
    // Continue with any code that should run after the simulation finishes

    this.resulthandle();
    // return this.state.resultsO
  };

  resulthandle = async () => {
    setTimeout(async () => {
      await axiosApiInstance
        .get(`api/v1/resO/?username=${localStorage.getItem("user")}`)
        .then((res) => {
          this.setState({ resultsO: res.data });
        })
        .catch((error) => {
          console.log("ERROR::: ", error.res);
        });

      // Continue with any code that should run after the simulation finishes
      message.success("Optimization finished");

      return this.state.resultsO;
    }, 3000);
  };

  DownloadLCSV = (plot, DWM) => {
    const link = document.createElement("a");
    if (plot == "Dynamics" || plot == "Usage") {
      if (
        DWM === "weeklyDyn_" ||
        DWM === "monthlyDyn_" ||
        DWM === "dailyDyn_" ||
        DWM === "weeklyUsage_" ||
        DWM === "monthlyUsage_"
      ) {
        link.href = `https://sptapp-files.s3.eu-west-3.amazonaws.com/${localStorage.getItem(
          "user"
        )}/opt/${DWM}${this.state.resultsR["optimizationID"]}.csv`; //${plot}${this.state.resultsR["simulationID"]}
        console.log("link", link.href);
        //link.download = `${plot}${this.state.resultsR["simulationID"]}.csv`; // Optional: Change the downloaded file name if needed
        //link.href = this.state.resultsR["outputCSV"]
        link.download = "output.csv"; // Optional: Change the downloaded file name if needed

        console.log("telecharge", link.href);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } else {
        //link.href = `C:/Users/habib/Desktop/VPP4ISLANDS/SPT/SPT V3/SPT/frontend/gui/src/components/Sim_outputs/${plot}${2}.csv`; // Provide the correct path to your CSV file
        link.href = `https://sptapp-files.s3.eu-west-3.amazonaws.com/${localStorage.getItem(
          "user"
        )}/opt/${plot}O${this.state.resultsR["optimizationID"]}.csv`; //${plot}${this.state.resultsR["simulationID"]}
        console.log("link", link.href);
        //link.download = `${plot}${this.state.resultsR["simulationID"]}.csv`; // Optional: Change the downloaded file name if needed
        //link.href = this.state.resultsR["outputCSV"]
        link.download = "output.csv"; // Optional: Change the downloaded file name if needed

        console.log("telecharge", link.href);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    } else {
      link.href = `https://sptapp-files.s3.eu-west-3.amazonaws.com/${localStorage.getItem(
        "user"
      )}/opt/${plot}O${this.state.resultsR["optimizationID"]}.csv`; //${plot}${this.state.resultsR["simulationID"]}
      console.log("link", link.href);
      //link.download = `${plot}${this.state.resultsR["simulationID"]}.csv`; // Optional: Change the downloaded file name if needed
      //link.href = this.state.resultsR["outputCSV"]
      link.download = "output.csv"; // Optional: Change the downloaded file name if needed

      console.log("telecharge", link.href);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };

  DownloadPNG = (plot) => {
    const link = document.createElement("a");
    if (plot == "Dynamics") {
      plot = "dynamics";
    }
    if (plot == "Cash") {
      plot = "cashflow";
    }
    if (plot == "Efficiency") {
      plot = "efficiency";
    }
    if (plot == "Usage") {
      plot = "usage";
    }

    //link.href = `C:/Users/habib/Desktop/VPP4ISLANDS/SPT/SPT V3/SPT/frontend/gui/src/components/Sim_outputs/${plot}${2}.csv`; // Provide the correct path to your CSV file
    link.href = `https://sptapp-files.s3.eu-west-3.amazonaws.com/${localStorage.getItem(
      "user"
    )}/images/imageOPT/${plot}_${this.state.resultsR["optimizationID"]}.png`; //${plot}${this.state.resultsR["simulationID"]}
    //link.download = `${plot}${this.state.resultsR["simulationID"]}.csv`; // Optional: Change the downloaded file name if needed
    //link.href = this.state.resultsR["outputCSV"]
    link.download = `${plot}_${this.state.resultsR["optimizationID"]}.png`; // Optional: Change the downloaded file name if needed

    console.log("telecharge", link.href);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  DownloadCSV = () => {
    const { resultsR } = this.state;

    //Object.values(resultsR.outputCSV).join('\n');
    /*  const outputCSV = Papa.parse(resultsR.outputCSV);
    const rows = outputCSV.split('\n');
    //const outputCSV =  Object.values(resultsR.outputCSV).join('\n'); //  this.state.resultsR["outputCSV"];
    console.log("csv csv",this.state.resultsR["outputCSV"])
    
    const csvData = rows.map((row, index) => (
      <div key={index}>
        {row.split(',').map((item, innerIndex) => (
          <span key={innerIndex}>{item} </span>
        ))}
      </div>
    ));
  
    // Convert the CSV data to a Blob
    const blob = new Blob([csvData], { type: 'text/csv' }); */

    const outputCSV = Object.values(resultsR.outputCSV).join("\n");
    console.log("csv csv", this.state.resultsR["outputCSV"]);

    // Transform the CSV data as needed (example: splitting rows and cells)
    const transformedData = outputCSV.split("\n").map((row) => row.split(","));

    // Convert the transformed data back to a CSV string
    const transformedCSV = transformedData
      .map((row) => row.join(","))
      .join("\n");

    // Create a Blob with the transformed CSV data
    const blob = new Blob([transformedCSV], { type: "text/csv" });

    // Convert the CSV data to a Blob

    // Create a temporary anchor element
    const a = document.createElement("a");
    a.href = URL.createObjectURL(blob);

    // Set the download attribute with the desired filename
    a.download = "output.csv";

    // Append the anchor to the document body
    document.body.appendChild(a);

    // Trigger a click on the anchor to start the download
    a.click();

    // Remove the anchor from the document body
    document.body.removeChild(a);

    // Revoke the blob URL to free up resources
    URL.revokeObjectURL(a.href);
  };

  plotChart = (csvData, y) => {
    console.log("Raw CSV Data:", csvData);

    if (y == "Usage") {
      this.state.csvArray = csvData.data;
    }

    if (y == "Dynamics") {
      console.log("hey hey");
      this.state.csvArray = csvData.data;
    }
    if (y == "dailyDyn_") {
      console.log("hey hey");
      this.state.csvArray = csvData.data; //JSON.parse(csvData.Dynamics);
    }

    if (y == "weeklyDyn_") {
      console.log("hey hey");
      this.state.csvArray = csvData.data; //JSON.parse(csvData.Dynamics);
    }
    if (y == "monthlyDyn_") {
      console.log("hey hey");
      this.state.csvArray = csvData.data; //JSON.parse(csvData.Dynamics);
    }

    if (y == "weeklyUsage_") {
      console.log("hey hey");
      this.state.csvArray = csvData.data; //JSON.parse(csvData.Dynamics);
    }
    if (y == "monthlyUsage_") {
      console.log("hey hey");
      this.state.csvArray = csvData.data; //JSON.parse(csvData.Dynamics);
    }

    if (y == "SoC") {
      console.log("hey hey");
      this.state.csvArray = csvData.data;
    }

    if (y == "Cash") {
      console.log("hey hey");
      this.state.csvArray = csvData.data;
    }

    if (y == "NPV") {
      console.log("hey hey");
      this.state.csvArray = csvData.data;
    }

    if (y == "Efficiency") {
      console.log("hey hey");
      this.state.csvArray = csvData.data;
    }

    console.log("Parsed Data:", this.state.csvArray);
    const Arraycsv = this.state.csvArray;

    if (Arraycsv.length > 0) {
      const labels = Arraycsv.slice(1).map((row) => row[0]); // Extracting labels from the first column of each row

      // Extract values for each dataset
      const datasets = Arraycsv[0]
        .slice(1)
        .map((datasetLabel, datasetIndex) => {
          const color = randomColor();
          return {
            label: datasetLabel,
            data: Arraycsv.slice(1).map((row) => row[datasetIndex + 1]),
            fill: false,
            backgroundColor: color,
            borderColor: color,
            borderWidth: 1,
          };
        });

      console.log("Labels:", labels);
      console.log("Datasets:", datasets);
      //this.initializeChart();
      // Initialize the chart if it doesn't exist
      if (!this.chart) {
        console.log("Chart not created, initializing...");
        this.initializeChartB();
      }

      // Update the chart data if it exists
      if (this.chart) {
        // Clear existing datasets
        // this.chart.data.datasets = [];

        // Add new datasets
        this.chart.data.datasets = datasets;

        // Update x-axis labels
        this.chart.data.labels = labels;
        if (
          y == "Cash" ||
          y == "Usage" ||
          y == "weeklyDyn_" ||
          y == "monthlyDyn_" ||
          y == "monthlyUsage_" ||
          y == "weeklyUsage_"
        ) {
          this.chart.config.type = "bar";
          if (y == "weeklyDyn_") {
            this.chart.options.scales.y.title.text = "kWh";
            this.chart.options.scales.x.title.text = "Weeks";
            this.chart.options.scales.y.stacked = true;
            this.chart.options.scales.x.stacked = true;
            this.chart.options.plugins.title.text = "Energy system behaviors";
          }
          if (y == "monthlyDyn_") {
            this.chart.options.scales.y.title.text = "kWh";
            this.chart.options.scales.x.title.text = "Months";
            this.chart.options.scales.y.stacked = true;
            this.chart.options.scales.x.stacked = true;
            this.chart.options.plugins.title.text = "Energy system behaviors";
          }
          if (y == "weeklyUsage_") {
            // Update the chart type to line

            this.chart.options.scales.y.title.text = "%";
            this.chart.options.scales.x.title.text = "Weeks";

            this.chart.options.scales.y.stacked = true;
            this.chart.options.scales.x.stacked = true;
            this.chart.options.plugins.title.text =
              "percentage of electricity usage";
          }
          if (y == "monthlyUsage_") {
            // Update the chart type to line

            this.chart.options.scales.y.title.text = "kWh";
            this.chart.options.scales.x.title.text = "Months";

            this.chart.options.scales.y.stacked = true;
            this.chart.options.scales.x.stacked = true;
            this.chart.options.plugins.title.text =
              "percentage of electricity usage";
          }
          if (y == "Cash") {
            this.chart.options.scales.y.title.text = "€";
            this.chart.options.scales.x.title.text = "Years";

            this.chart.options.scales.y.stacked = true;
            this.chart.options.plugins.title.text =
              "Cash flow during project life time";
          }
          if (y == "Usage") {
            // Update the chart type to line

            this.chart.options.scales.y.title.text = "%";
            this.chart.options.scales.x.title.text = "Days";

            this.chart.options.scales.y.stacked = true;
            this.chart.options.scales.x.stacked = true;
            this.chart.options.plugins.title.text =
              "percentage of electricity usage";
          }
          //this.chart.config.options.plugins.title.display = true;
        } else {
          this.chart.config.type = "line";
          if (y == "Dynamics") {
            this.chart.options.scales.y.title.text = "kWh";
            this.chart.options.scales.x.title.text = "Hour";
            this.chart.options.scales.y.stacked = false;
            this.chart.options.plugins.title.text = "Energy system behaviors";
          }
          if (y == "dailyDyn_") {
            this.chart.options.scales.y.title.text = "kWh";
            this.chart.options.scales.x.title.text = "Days";
            this.chart.options.scales.y.stacked = false;
            this.chart.options.plugins.title.text = "Energy system behaviors";
          }
          if (y == "SoC") {
            this.chart.options.scales.y.title.text = "SoC%";
            this.chart.options.scales.x.title.text = "Hour";
            this.chart.options.scales.y.stacked = false;
            this.chart.options.plugins.title.text = "State of Charges";
          }
          if (y == "Efficiency") {
            this.chart.options.scales.y.title.text = "%";
            this.chart.options.scales.x.title.text = "Weeks";
            this.chart.options.scales.y.stacked = false;
            this.chart.options.plugins.title.text =
              "Efficiency of energy system";
          }
          if (y == "NPV") {
            this.chart.options.scales.y.title.text = "€";
            this.chart.options.scales.x.title.text = "Years";
            this.chart.options.scales.y.stacked = false;
            this.chart.options.plugins.title.text =
              "Net Present Value during project life time";
          }
        }

        // Update the chart
        this.chart.update();
      }
    } else {
      console.error("No data found in the CSV file.");
    }
  };

  initializeChartB() {
    const ctx = this.chartRef.current.getContext("2d");

    this.chart = new Chart(ctx, {
      type: "bar", // Set your desired chart type
      data: {
        labels: [],
        datasets: [],
      },
      options: {
        plugins: {
          title: {
            display: true,
            position: "top",
            text: "Optimisation charts",
            //(ctx) => 'Zoom: ' + zoomStatus() + ' , Pan: ' + panStatus()
          },

          //zoom: zoomOptions,

          zoom: {
            pan: {
              enabled: true,
              mode: "x", // allows panning along the x-axis
            },
            zoom: {
              wheel: {
                enabled: true, // enables zooming with the mouse wheel
              },
              pinch: {
                enabled: true, // enables zooming on mobile devices
              },
              mode: "x", // allows zooming along the x-axis
            },
          },
        },
        elements: {
          point: {
            radius: 0,
          },
        },
        responsive: true,
        scales: {
          x: {
            //suggestedMax: 8760,
            beginAtZero: true,
            title: {
              display: true,
              text: "Time", // Y-axis label
            },
          },
          y: {
            beginAtZero: true,
            stacked: this.state.staked,
            //  suggestedMax: 200000,

            title: {
              display: true,
              text: "kWh", // Y-axis label
            },
          },
        },

        /*  onClick(e) {
        console.log(e.type);
      } */
      },
    });

    console.log("i am created");
  }

  handleCheckboxSCChange = (e) => {
    if (e && e.target) {
      this.setState({ isCheckedSC: e.target.checked });
    }
  };

  handleCheckboxTRChange = (e) => {
    if (e && e.target) {
      this.setState({ isCheckedT: e.target.checked });
    }
  };

  handleClick = (e, items) => {
    //this.props.configurationID= items.configurationID
    this.state.itemo = items.configurationID;
    console.log("itemo", this.state.itemo);
    //you can perform setState here
    this.setState({ selectedConfiguration: items.name });
    this.formRef.current.setFieldsValue({ idconfig: items.configurationID }); // Set the form field value

    this.state.verrou = true;
  };

  typeChange = (e) => {
    //this.props.configurationID= items.configurationID
    console.log("Event object:", e);
    const key = e.key;
    this.setState({ SelectedType: e.item.props.children, SType: key });
    console.log("slectedtype", this.state.SelectedType);
    // Display type in the console
    console.log("Selected Type:", key);

    this.setState({ selectedtypeO: key });

    //console.log("itemo", this.state.type)
    //you can perform setState here
  };

  //     ***********    Check boxes ***********

  handleCheckboxChangeSelect = (checkboxName) => {
    if (
      checkboxName !== "isCheckedfS" &&
      checkboxName !== "isCheckedRet" &&
      checkboxName !== "isCheckedfD" &&
      checkboxName !== "isCheckedSelf" &&
      checkboxName !== "isCheckedP" &&
      checkboxName !== "fNPV" &&
      checkboxName !== "isCheckedIrr"
    ) {
      this.setState(
        (prevState) => ({
          [checkboxName]: !prevState[checkboxName],
        }),
        () => this.updateSelectedCheckboxes(checkboxName)
      );
    }
  };

  updateSelectedCheckboxes = (check) => {
    const selectedCheckboxes = Object.keys(this.state)
      .filter(
        (checkbox) =>
          this.state[checkbox] === true &&
          (checkbox === "Dynamics" ||
            checkbox === "SoC" ||
            checkbox === "Usage" ||
            checkbox === "Efficiency" ||
            checkbox === "Cashflow" ||
            checkbox === "NPV")
      )
      .map((value) => ({ value }));
    if (
      check !== "isCheckedfS" &&
      check !== "isCheckedRet" &&
      check !== "isCheckedfD" &&
      check !== "isCheckedSelf" &&
      check !== "isCheckedP" &&
      check !== "fNPV" &&
      check !== "isCheckedIrr"
    ) {
      this.setState({ selectedCheckboxes });
    }
  };

  handleCheckboxEChange = (e) => {
    if (e && e.target) {
      this.setState({ Dynamics: e.target.checked });
    }
  };

  handleCheckboxSChange = (e) => {
    if (e && e.target) {
      this.setState({ Usage: e.target.checked });
    }
  };

  handleCheckboxBChange = (e) => {
    if (e && e.target) {
      this.setState({ Efficiency: e.target.checked });
    }
  };

  handleCheckboxCChange = (e) => {
    if (e && e.target) {
      this.setState({ Cashflow: e.target.checked });
    }
  };

  handleCheckboxTChange = (e) => {
    if (e && e.target) {
      this.setState({ NPV: e.target.checked });
    }
  };

  handleCheckboxTRChange = (e) => {
    if (e && e.target) {
      this.setState({ isCheckedT: e.target.checked });
    }
  };

  handleCheckboxAChange = (e) => {
    if (e && e.target) {
      this.setState({ SoC: e.target.checked });
    }
  };

  handleCheckboxfNChange = (e) => {
    if (e && e.target) {
      this.setState({ fNPV: e.target.checked });
    }
  };

  handleCheckboxfSChange = (e) => {
    if (e && e.target) {
      this.setState({ isCheckedfS: e.target.checked });
    }
  };

  handleCheckboxfDChange = (e) => {
    if (e && e.target) {
      this.setState({ isCheckedfD: e.target.checked });
    }
  };

  handleCheckboxRetChange = (e) => {
    if (e && e.target) {
      this.setState({ isCheckedRet: e.target.checked });
    }
  };

  handleCheckboxIrrChange = (e) => {
    if (e && e.target) {
      this.setState({ isCheckedIrr: e.target.checked });
    }
  };

  handleCheckboxSelfChange = (e) => {
    if (e && e.target) {
      this.setState({ isCheckedSelf: e.target.checked });
    }
  };

  handleCheckboxPChange = (e) => {
    if (e && e.target) {
      this.setState({ isCheckedP: e.target.checked });
    }
  };

  handleClick2 = (e, item) => {
    // Handle click logic
  };

  handleChange = async (e) => {
    //console.log("optimisation nbr Nbr", this.state.optID)
    if (this.state.verrou) {
      const configa = {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          // 'Authorization': AUTH_TOKEN,
        },

        params: {
          configurationID: this.state.itemo,
          optimizationID: this.state.optID,
          username: localStorage.getItem("user"),
          //"simulationID": simulationID
        },
      };

      message.error("Please wait for the simulation finishing");
      this.setState({ showSpin: true });
      //console.log("testttiinnggngg")
      // console.log(datar["configurationID"])
      //console.log(this.state.simulations[2]["simulationID"])
      const promiseO = await axiosApiInstance.get(
        "opti/v1/optimisation/",
        configa
      );

      await this.getResults();

      message.success("Optimization is finished");

      //const dataPromiseO = promiseO.then((response) => response.data);
      /*  .then((response) => response.data)
    .catch((error) => {
      console.error('Error fetching data:', error);
    }); */
      // console.log(datar["configurationID"])
      //console.log(this.state.simulations[2]["simulationID"])
      //const promiseO = axios.get('https://www.sptapp.eu/opti/v1/optimisation/', configa );
      //const dataPromiseO = promiseO.then((response) => response.data);
      //console.log(configuration);

      //   setTimeout(() => { message.success('The simulation is finished succssefully');
      // }, 12000)

      // Set a timeout to hide the Spin component after 15 seconds

      //this.setState({ showSpin: false });
      return this.state.resultsO;
      this.state.verrou = false;
      //return dataPromiseO
    }
  };

  async handleClickRO(e, items) {
    //this.props.configurationID= items.configurationID
    this.state.itemro = items.resultOPTID;
    console.log("Result", this.state.itemro);

    const resultOPTID = items.resultOPTID;
    this.setState({ verrouPDF: true, selectedOPT: items.name });
    await axiosApiInstance
      .get(`api/v1/resO/${resultOPTID}`)
      .then((resid) => {
        this.setState({ resultsR: resid.data });
        console.log("resid", resid.data["optimizationID"]);
        this.setState({ IRRR: resid.data["outcomes"]["IRR"] });
        this.setState({ DECARBR: resid.data["outcomes"]["DECRAB"] });
        this.setState({ SAVR: resid.data["outcomes"]["Savings"] });
        this.setState({ RETR: resid.data["outcomes"]["Return"] });
        this.setState({ LCOER: resid.data["outcomes"]["LCOE"] });
        this.setState({ PAYR: resid.data["outcomes"]["Payback"] });
        this.setState({ BUILDR: resid.data["outcomes"]["BuildingLCOE"] });
        this.setState({ SELF: resid.data["outcomes"]["resultsGlobal"] });
        this.setState({ NAMES: resid.data["outcomes"]["namesLoad"] });
        this.setState({ ABATR: resid.data["outcomes"]["AbatCost"] });
        this.plotshandle(resid.data["optimizationID"]);
        this.setState({ Foot: resid.data["outcomes"]["Foot"] });

        //sessionStorage.setItem('data', JSON.stringify(resid)) ;
        //console.log("storga", sessionStorage);
      })

      .catch((error) => {
        console.log("ERROR::: ", error.resid);
      });

    // this.setState({simulationRID: this.state.results[items.resultID]["simulationID"]})
    // console.log(this.state.displayr)

    this.setState({ verrouS: true });

    //simulationID=
    //     axios.get(`https://www.sptapp.eu/api/dss/`)
    //     .then (ress =>{
    //      this.setState({ simulations: ress.data});  console.log("storga2", ress.data) ;

    //      })
    //     .catch(error => {
    //        console.log("ERROR::: ",error.ress);})
    // // 			//you can perform setState here
  }

  handleSubmit = async (
    values,
    requestType,
    optimizationID,
    configurationID
  ) => {
    // const country = useRef()
    // const town = useRef()
    //e.preventDefault();
    //const date_creation =e.target.elements.date.value;
    //<Progress strokeLinecap="butt" percent={75} />
    if (this.state.verrou == true) {
      //const kpi =e.target.elements.objective.value;
      const kpi1 = this.state.fNPV; // boucle for
      const kpi2 = this.state.isCheckedfS;
      const kpi3 = this.state.isCheckedfD;
      const kpi4 = this.state.isCheckedSelf; // boucle for
      const kpi5 = this.state.isCheckedP;
      const kpi6 = this.state.isCheckedRet;
      const kpi7 = this.state.isCheckedIrr;
      const name = values.name;
      const iter = 20;
      if (this.state.SType == "AI") {
        const iter = parseInt(values.iter);
        console.log("iterrr", values.iter);
      }
      console.log("nammeee", values.name);

      //

      //console.log( plots, selectedtypeO, simulationID)

      let kpi = [];
      if (kpi1) {
        kpi.push("findNPV");
      }

      if (kpi2) {
        kpi.push("findSavings");
      }

      if (kpi3) {
        kpi.push("findGlobalDecarbonisation");
      }

      if (kpi4) {
        kpi.push("findSelfConsumption");
      }

      if (kpi5) {
        kpi.push("findPayback");
      }

      if (kpi6) {
        kpi.push("findSimpleReturn");
      }

      if (kpi7) {
        kpi.push("findIRR");
      }

      console.log("hiiiiilsss", kpi.length);
      if (kpi.length > 0) {
        console.log("hiiiiilsss", kpi.length);
        this.setState(
          (prevState) => ({
            kpi: [...prevState.kpi, ...kpi],
            i: prevState.i + kpi.length,
          }),
          () => this.handlePostRequest(requestType, name, iter)
        );
      }
      if (this.state.SType == "AI") {
        console.log("iterrr", kpi.length, iter, parseInt(values.iter));
        this.handlePostRequest(requestType, name, parseInt(values.iter));
      }
    } else {
      message.error("Optimization type is missing");
    }
  };

  async plotshandle(y) {
    await axiosApiInstance
      .get(`api/v1/Opt/${y}`)
      .then((ressid) => {
        this.setState({ plotR: ressid.data["plots"] });
        console.log("plotR", this.state.plotR);
        this.plotRT(ressid.data["plots"]);
      })

      .catch((error) => {
        console.log("ERROR::: ", error.resid);
      });

    //await this.plotRT()
  }

  async plotRT(X) {
    if (X[0] == true) {
      this.setState((prevState) => ({
        plotRT: [...prevState.plotRT, "Dynamics"],
      }));
    }
    if (X[1] == true) {
      this.setState((prevState) => ({
        plotRT: [...prevState.plotRT, "SoC"],
      }));
    }
    if (X[2] == true) {
      this.setState((prevState) => ({
        plotRT: [...prevState.plotRT, "Cash"],
      }));
    }
    if (X[3] == true) {
      this.setState((prevState) => ({
        plotRT: [...prevState.plotRT, "NPV"],
      }));
    }
    if (X[4] == true) {
      this.setState((prevState) => ({
        plotRT: [...prevState.plotRT, "Usage"],
      }));
    }
    if (X[5] == true) {
      this.setState((prevState) => ({
        plotRT: [...prevState.plotRT, "Efficiency"],
      }));
    }
  }

  handlePostRequest = async (requestType, name, iter) => {
    this.state.i = 0;
    console.log("IIIIIIter", this.state.i, iter);

    const selectedtypeO = this.state.selectedtypeO;
    const configurationID = this.state.itemo;
    console.log("configurationID", configurationID);
    //const plots=e.target.elements.P1.checked;

    const plots1 = this.state.Dynamics; // boucle for
    const plots2 = this.state.SoC;
    const plots3 = this.state.Cashflow;
    const plots4 = this.state.NPV; // boucle for
    const plots5 = this.state.Usage;
    const plots6 = this.state.Efficiency;
    const usages2 = this.state.isCheckedSC;
    const usages3 = this.state.isCheckedT;
    const usages = [usages2, usages3];
    //const usages = [true];
    console.log("usages", usages);

    const plots = [plots1, plots2, plots3, plots4, plots5, plots6];

    console.log(plots, selectedtypeO, configurationID);

    switch (requestType) {
      case "post":
        console.log("Before POST Request:", this.state.kpi);

        await axiosApiInstance
          .post("api/v1/createO/", {
            selectedtype: selectedtypeO,
            name: name,
            iter: iter,
            username: localStorage.getItem("user"),
            kpi: this.state.kpi,
            plots: plots,
            configurationID: configurationID,
            usages: usages,
          })
          .then((resOpt) => {
            this.setState({ optID: resOpt.data["optimizationID"] });
            this.state.i = 0;
            // this.state.verrou=true

            message.success("Ready for optimisation");

            console.log("testopt", resOpt);

            // console.log(res.data["simulationID"]);
            // console.log(simID);
          })
          .catch((error) => console.error(error));
        this.state.i = 0;
        this.state.kpi = [];
      /*  case 'put': return axios.put(`https://www.sptapp.eu/api/v1/Opt/${optimizationID}/updateO/`, {
        kpi: kpi,  
        plots:plots
        }) 
        .then (res =>console.log(res))
        .catch (error => console.error(error))
        
      
      case 'delete': return axios.delete(`https://www.sptapp.eu/api/v1/Opt/${optimizationID}/deleteO/`) 
        .then (res =>console.log(res))
        .catch (error => console.error(error))
     */

      // message.success("Ready for optimisation")
    }
    setTimeout(() => {
      //this.state.verrou = true;
      this.handleChange();
    }, 3000);
  };

  render() {
    const {
      configurations,
      selectedConfiguration,
      isPopupVisible,
      selectedOPT,
    } = this.state;

    const SelectedType = this.state.SelectedType;
    const selectedtypeO = this.state.selectedtypeO;

    const { showSpin } = this.state;
    //const {BUILDR} = this.state;

    const columns = [
      {
        title: "System name",
        dataIndex: "Name",
        key: "Name",
        render: (text) => <a>{text}</a>,
      },
      {
        title: "Energy-delivered kWh",
        dataIndex: "Energy-Delivered-kWh",
        key: "Energy-Delivered-kWh",
      },
      {
        title: "Capacity-factor",
        dataIndex: "Capacity-Factor",
        key: "Capacity-Factor",
      },
      {
        title: "capital-cost €",
        dataIndex: "Capital-Cost",
        key: "Capital-Cost",
      },

      {
        title: "Operational-cost €",
        dataIndex: "Operational-Cost",
        key: "Operational-Cost",
      },

      {
        title: "Levelised-cost €kWh",
        dataIndex: "Levelised-Cost",
        key: "Levelised-Cost",
      },
    ];

    const columnl = [
      {
        title: "Load name",
        dataIndex: "System-Level",
        key: "System-Level",
        render: (text) => <a>{text}</a>,
      },
      {
        title: " Grid-kWh",
        dataIndex: "Grid-Delivered-kWh",
        key: "Grid-Delivered-kWh",
      },
      {
        title: " System-kWh",
        dataIndex: "System-Delivered-kWh",
        key: "System-Delivered-kWh",
      },
      {
        title: "Self-Consumption %",
        dataIndex: "Self-Consumption-%",
        key: "Self-Consumption-%",
      },
      {
        title: "LCOE-€kWh",
        dataIndex: "Levelised-Cost-€kWh",
        key: "Levelised-Cost-€kWh",
      },
      {
        title: "Decarb %",
        dataIndex: "Decarbonisation-%",
        key: "Decarbonisation-%",
      },

      {
        title: "Emissions-co2ekWh",
        dataIndex: "Emissions-co2ekWh",
        key: "Emissions-co2ekWh",
      },
    ];

    const dataC = this.state.resultsR["components"];
    const dataL = this.state.resultsR["loadsS"];
    if (Array.isArray(dataL) && dataL.length > 0) {
      console.log("hhh");
      this.state.SystemDelivered = dataL.reduce((total, item) => {
        return total + (item["System-Delivered-kWh"] || 0); // Fallback to 0 if undefined
      }, 0);
    }
    const test = this.state.SType;

    console.log("hh", this.state.SelectedType);
    return (
      <>
        {/*   <form
          onSubmit={(e) =>
            this.handleSubmit(
              e,
              this.props.requestType,
              this.props.optimizationID,
              this.props.configurationID
            )
          } > */}
        <Form
          onFinish={(values) =>
            this.handleSubmit(
              values,
              this.props.requestType,
              this.props.optimizationID,
              this.props.configurationID
            )
          } // handle form submission with values
          //onFinish={this.handleSubmit} // Use this to handle form submission
          ref={this.formRef}
          name="configurationForm"
          initialValues={{ name: "", iter: 99, idconfig: null }} // Initial state for the form
        >
          <div className="container">
            {/* Part 1 */}
            <div
              style={{
                display: "flex",
                width: "170vh",
                marginBottom: 10,
                backgroundColor: "#f0f0f0",
                padding: 5,
                border: "solid 1px",
                borderColor: "#cccccc",
              }}
            >
              <Card
                style={{
                  backgroundColor: "#ffff",
                  width: "100vh",
                  margin: "0 10px",
                }}
              >
                <div>
                  <Divider orientation="center">
                    <b> Choose Your objective function </b>
                    <div
                      style={{
                        position: "relative",
                        display: "inline-block",
                      }}
                    >
                      <InfoCircleTwoTone
                        onClick={this.handleIconClick}
                        style={{ fontSize: "24px", cursor: "pointer" }}
                      />
                      {isPopupVisible && (
                        <div
                          ref={this.popupRef}
                          style={{
                            position: "absolute",
                            top: "30px", // Adjust as needed
                            left: "0",
                            border: "1px solid #ccc",
                            backgroundColor: "white",
                            padding: "10px",
                            boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
                            zIndex: 1000, // Ensures the popup is above other elements
                          }}
                        >
                          Mainly four methods are proposed to optimize your
                          <br></br> savings and/or decarbonisation. For a
                          specific optimization study kindly contact
                          <br></br> RDIUP team.
                        </div>
                      )}{" "}
                    </div>
                  </Divider>{" "}
                </div>
                <Row
                  gutter={{
                    xs: 8,
                    sm: 16,
                    md: 24,
                    lg: 32,
                  }}
                >
                  {/*  <>
                      <Dropdown
                        overlay={
                          <Menu>
                            <div>
                              {" "}
                              {this.state.configurations.map((items) => (
                                <Menu.Item
                                  onClick={(e) => this.handleClick(e, items)}
                                  style={{ minWidth: 0, flex: "auto" }}
                                  key={items.configurationID}
                                >
                                  {" "}
                                  Config {String(items.name)}
                                </Menu.Item>
                              ))}{" "}
                            </div>
                          </Menu>
                        }
                        trigger={["click"]}
                      >
                        <Button
                          className="ant-dropdown-link"
                          icon={<VerticalAlignBottomOutlined />}
                          onClick={(e) => e.preventDefault()}
                        >
                          {selectedConfiguration
                            ? `Config ${selectedConfiguration}`
                            : "Configuration ID"}
                        </Button>
                      </Dropdown>
                    </> */}

                  <Form.Item label={"Optimisation type"}>
                    <Dropdown
                      name="typeobj"
                      overlay={
                        <Menu onClick={this.typeChange}>
                          <Menu.Item key="single"> Single objective </Menu.Item>
                          <Menu.Item key="multi">
                            {" "}
                            Multiple objective{" "}
                          </Menu.Item>
                          <Menu.Item key="AI">
                            {" "}
                            AI-based optimization{" "}
                          </Menu.Item>
                          <Menu.Item key="Flex"> Flexibility </Menu.Item>
                        </Menu>
                      }
                      trigger={["click"]}
                    >
                      <Button
                        className="ant-dropdown-link"
                        onClick={(e) => e.preventDefault()}
                      >
                        {selectedtypeO
                          ? `Optimisation ${selectedtypeO}`
                          : "Select Optimisation Type"}
                        {/*                         {SelectedType || "Select Optimisation Type"}
                         */}{" "}
                      </Button>
                    </Dropdown>
                  </Form.Item>
                </Row>
                <br></br>
                {test !== "AI" ? (
                  <>
                    <Row
                      gutter={{
                        xs: 8,
                        sm: 16,
                        md: 24,
                        lg: 32,
                      }}
                    >
                      <Col className="gutter-row" span={6}>
                        {" "}
                        <div>
                          <Checkbox
                            value="findSavings"
                            checked={this.state.isCheckedfS}
                            onChange={(e) => this.handleCheckboxfSChange(e)}
                          >
                            {" "}
                            Savings{" "}
                          </Checkbox>
                        </div>{" "}
                      </Col>
                      <Col className="gutter-row" span={6}>
                        {" "}
                        <div>
                          <Checkbox
                            value="findDecarbonisation"
                            checked={this.state.isCheckedfD}
                            onChange={(e) => this.handleCheckboxfDChange(e)}
                          >
                            {" "}
                            Decarbonisation{" "}
                          </Checkbox>
                        </div>{" "}
                      </Col>
                    </Row>
                    <div style={{ maxWidth: "500px" }}>
                      {/*       <Divider orientation="left"></Divider>
                       */}{" "}
                    </div>
                    <Row gutter={[16, 8]}> </Row>
                  </>
                ) : (
                  <Form.Item
                    label="Iterations:"
                    name="iter" // This must match the form values key
                    rules={[
                      { required: true, message: "Iterations are required" }, // Validation rule if needed
                    ]}
                  >
                    {/* label htmlFor="name" className="country-label">
                        Iterations:{" "}
                      </label> */}
                    <Input
                      //value="is required"
                      style={{
                        maxWidth: "206px",
                        marginLeft: "24px",
                        gap: "18px",
                        maxHeight: "200px",
                      }}
                      type="number"
                      placeholder="99"
                      // name="iter"
                    />
                  </Form.Item>
                )}
                <div style={{ maxWidth: "500px" }}></div>
                {/*
<Row gutter={[16, 8]}>  <Col className="gutter-row" span={6}>   <div >
<Checkbox value="Cashflow" checked={this.state.isCheckedIrr} onChange={(e) => this.handleCheckboxIrrChange(e)}> findIRR </Checkbox></div> </Col>

</Row> */}
                <div style={{ maxWidth: "500px" }}>
                  <Divider orientation="center"> </Divider>{" "}
                </div>
                <Row
                  gutter={{
                    xs: 8,
                    sm: 16,
                    md: 24,
                    lg: 32,
                  }}
                >
                  {/* <Col className="gutter-row" span={6}> <div>

<Checkbox value="Trading" checked={this.state.isCheckedA} onChange={this.handleCheckboxAUChange}> Autonomy </Checkbox> 
</div> </Col> */}
                  <br></br>
                  <Col className="gutter-row" span={6}>
                    {" "}
                    <div>
                      <Checkbox
                        value="Self"
                        checked={this.state.isCheckedSC}
                        onChange={this.handleCheckboxSCChange}
                      >
                        {" "}
                        Self-consumption{" "}
                      </Checkbox>
                    </div>{" "}
                  </Col>

                  <Col className="gutter-row" span={6}>
                    {" "}
                    <div>
                      <Checkbox
                        value="Trading"
                        checked={this.state.isCheckedT}
                        onChange={this.handleCheckboxTRChange}
                      >
                        {" "}
                        Trading{" "}
                      </Checkbox>
                    </div>{" "}
                  </Col>
                </Row>
                <div style={{ maxWidth: "500px" }}>
                  <Divider orientation="center"> </Divider>
                  <div
                    style={{
                      display: "inline-block",
                      border: "1px solid #cccc", // Border color and thickness
                      borderRadius: "8px", // Rounded corners
                      padding: "5px", // Padding around the image
                      cursor: "pointer",
                      marginBottom: "20px",
                    }}
                    onClick={this.handleModalOpen}
                  >
                    <b>Select plots</b>
                    <img
                      src={plotButtonImageO}
                      alt="Choose Plots"
                      //onClick={this.handleModalOpen}
                      style={{
                        cursor: "pointer",
                        width: "170px", // Set your desired size
                        height: "120px", // Set your desired size
                        marginBottom: "10px",
                      }}
                    />
                  </div>
                </div>
                <Modal
                  title="Select Options"
                  visible={this.state.visible}
                  onOk={this.handleModalClose}
                  onCancel={this.handleModalCancel}
                >
                  <Row gutter={[16, 8]}>
                    {" "}
                    <Col className="gutter-row" span={8}>
                      {" "}
                      <div>
                        <Checkbox
                          value="EnergyDynamics"
                          checked={this.state.Dynamics}
                          onChange={(e) => [
                            this.handleCheckboxChangeSelect("Dynamics"),
                            this.handleCheckboxEChange(e),
                          ]}
                        >
                          {" "}
                          Dynamics{" "}
                        </Checkbox>
                      </div>
                    </Col>
                    <Col className="gutter-row" span={8}>
                      {" "}
                      <div>
                        <Checkbox
                          value="StateOfCharge"
                          checked={this.state.SoC}
                          onChange={(e) => [
                            this.handleCheckboxChangeSelect("SoC"),
                            this.handleCheckboxAChange(e),
                          ]}
                        >
                          {" "}
                          SoC{" "}
                        </Checkbox>
                      </div>
                    </Col>{" "}
                  </Row>
                  <div style={{ maxWidth: "400px" }}>
                    <Divider orientation="left"> </Divider>{" "}
                  </div>
                  <Row gutter={[16, 8]}>
                    {" "}
                    <Col className="gutter-row" span={8}>
                      {" "}
                      <div>
                        <Checkbox
                          value="Cashflow"
                          checked={this.state.Cashflow}
                          onChange={(e) => [
                            this.handleCheckboxChangeSelect("Cashflow"),
                            this.handleCheckboxCChange(e),
                          ]}
                        >
                          {" "}
                          Cashflow{" "}
                        </Checkbox>
                      </div>
                    </Col>
                    <Col className="gutter-row" span={8}>
                      {" "}
                      <div>
                        <Checkbox
                          value="NPV"
                          checked={this.state.NPV}
                          onChange={(e) => [
                            this.handleCheckboxChangeSelect("NPV"),
                            this.handleCheckboxTChange(e),
                          ]}
                        >
                          {" "}
                          NPV{" "}
                        </Checkbox>{" "}
                      </div>{" "}
                    </Col>
                  </Row>
                  <div style={{ maxWidth: "400px" }}>
                    <Divider orientation="left"> </Divider>{" "}
                  </div>
                  <Row gutter={[16, 8]}>
                    <Col className="gutter-row" span={8}>
                      {" "}
                      <div>
                        <Checkbox
                          value="Usage"
                          checked={this.state.Usage}
                          onChange={(e) => [
                            this.handleCheckboxChangeSelect("Usage"),
                            this.handleCheckboxSChange(e),
                          ]}
                        >
                          {" "}
                          Usage{" "}
                        </Checkbox>
                      </div>
                    </Col>
                    <Col className="gutter-row" span={8}>
                      {" "}
                      <div>
                        <Checkbox
                          value="Efficiency"
                          checked={this.state.Efficiency}
                          onChange={(e) => [
                            this.handleCheckboxChangeSelect("Efficiency"),
                            this.handleCheckboxBChange(e),
                          ]}
                        >
                          {" "}
                          Efficiency{" "}
                        </Checkbox>
                      </div>
                    </Col>{" "}
                  </Row>
                </Modal>
              </Card>
              <br></br>
              {/* <div style={{
      display: 'block', width: 700, padding: 30
    }}> */}
              <Card
                style={{
                  backgroundColor: "#ffff",
                  width: "100vh",
                  margin: "0 10px",
                }}
              >
                <Divider orientation="center">
                  <b> Rename and select a configuration </b>
                </Divider>{" "}
                <Form.Item
                  label="Name:"
                  name="name"
                  rules={[
                    { required: true, message: "Name is required" }, // Validation rule
                  ]}
                >
                  <Input
                    //value="is required"
                    style={{
                      maxWidth: "206px",
                      gap: "18px",
                      maxHeight: "200px",
                    }}
                    placeholder="Enter Name"
                    type="text"
                    name="name"
                  />
                </Form.Item>
                <Form.Item
                  label={"ID Config"}
                  name="idconfig"
                  rules={[
                    { required: true, message: "Configuration ID is required" },
                  ]}
                >
                  <Dropdown
                    overlay={
                      <Menu>
                        <div>
                          {" "}
                          {this.state.configurations.map((items) => (
                            <Menu.Item
                              onClick={(e) => this.handleClick(e, items)}
                              style={{ minWidth: 0, flex: "auto" }}
                              key={items.configurationID}
                            >
                              {" "}
                              Config {String(items.name)}
                            </Menu.Item>
                          ))}{" "}
                        </div>
                      </Menu>
                    }
                    trigger={["click"]}
                  >
                    <Button
                      className="ant-dropdown-link"
                      icon={<VerticalAlignBottomOutlined />}
                      onClick={(e) => e.preventDefault()}
                    >
                      {selectedConfiguration
                        ? `Config ${selectedConfiguration}`
                        : "Select Configuration ID"}
                    </Button>
                  </Dropdown>
                </Form.Item>
                <br />
                <button
                  type="submit"
                  style={{
                    backgroundColor: "#082444",
                    color: "white",
                    cursor: "pointer",
                  }}
                >
                  Run optimization
                </button>
                {showSpin && <Spin size="large" />}
                {/* <Button
                      className="ant-dropdown-link"
                      icon={<ControlTwoTone />}
                      onClick={() => {
                        {
                          this.state.verrou
                            ? this.handleChange()
                            : message.error(
                                "create a optimisation and save before"
                              );
                        }
                      }}
                    >
                      {this.props.btnText}
                    </Button> */}
              </Card>
            </div>
            {/* Part 2 */}
            <div
              style={{
                width: "170vh",
                marginBottom: 10,
                backgroundColor: "#f0f0f0",
                padding: 5,
                border: "solid 1px",
                borderColor: "#cccccc",
              }}
            >
              {/* Your checkboxes */}
              <div
                style={{
                  flex: 1,
                  width: "600",
                  padding: "20px",
                  boxSizing: "border-box",
                }}
              >
                <h4> Result of optimisation </h4>
                <Dropdown
                  overlay={
                    <Menu>
                      <div>
                        {" "}
                        {this.state.resultsO.map((items) => (
                          <Menu.Item
                            onClick={(e) => this.handleClickRO(e, items)}
                            style={{ minWidth: 0, flex: "auto" }}
                            key={items.resultOPTID}
                          >
                            {" "}
                            Result {String(items.name)}
                          </Menu.Item>
                        ))}{" "}
                      </div>
                    </Menu>
                  }
                  trigger={["click"]}
                >
                  <Button
                    className="ant-dropdown-link"
                    icon={<VerticalAlignBottomOutlined />}
                    onClick={(e) => e.preventDefault()}
                  >
                    {selectedOPT ? `Result ${selectedOPT}` : "Result ID"}
                  </Button>
                </Dropdown>

                <br></br>
                <br></br>

                <div
                  style={{ display: "flex", gap: "24px", marginBottom: "16px" }}
                >
                  <Card
                    title={
                      <>
                        <EuroTwoTone twoToneColor="#eb2f96" /> IRR
                      </>
                    }
                    bordered={true}
                    style={{ height: "80px", flex: 1 }} // Set the desired height
                    className="centered-card"
                  >
                    {typeof this.state.IRRR === "number"
                      ? this.state.IRRR.toFixed(2) + "%"
                      : "N/A"}
                  </Card>
                  <Card
                    title={
                      <>
                        {" "}
                        <FundTwoTone /> Decarb
                      </>
                    }
                    bordered={false}
                    style={{ height: "80px", flex: 1 }} // Set the desired height
                    className="centered-card"
                  >
                    {typeof this.state.DECARBR === "number"
                      ? this.state.DECARBR.toFixed(2) + " gCO2kWh"
                      : "N/A"}
                  </Card>
                  <Card
                    title={
                      <>
                        {" "}
                        <InteractionTwoTone /> Return %
                      </>
                    }
                    bordered={false}
                    style={{ height: "80px", flex: 1 }} // Set the desired height
                    className="centered-card"
                  >
                    {typeof this.state.RETR === "number"
                      ? this.state.RETR.toFixed(2) + " %"
                      : "N/A"}
                  </Card>
                  <Card
                    title={
                      <>
                        {" "}
                        <HomeTwoTone /> Savings €
                      </>
                    }
                    bordered={false}
                    style={{ height: "80px", flex: 1 }} // Set the desired height
                    className="centered-card"
                  >
                    {typeof this.state.SAVR === "number"
                      ? -1 * this.state.SAVR.toFixed(0) + " €"
                      : "N/A"}
                  </Card>
                </div>

                <div
                  style={{ display: "flex", gap: "24px", marginBottom: "16px" }}
                >
                  <Card
                    title={
                      <>
                        {" "}
                        <ThunderboltTwoTone /> LCOE
                      </>
                    }
                    bordered={true}
                    style={{ height: "80px", flex: 1 }}
                    className="centered-card"
                  >
                    {typeof this.state.LCOER === "number"
                      ? this.state.LCOER.toFixed(2) + " €/kWh"
                      : "N/A"}
                  </Card>
                  <Card
                    title={
                      <>
                        {" "}
                        <CalendarTwoTone /> Payback
                      </>
                    }
                    bordered={false}
                    style={{ height: "80px", flex: 1 }}
                    className="centered-card"
                  >
                    {typeof this.state.PAYR === "number"
                      ? this.state.PAYR.toFixed(2) + " years"
                      : "N/A"}
                  </Card>
                  <Card
                    title={
                      <>
                        {" "}
                        <DiffTwoTone /> AbatCost
                      </>
                    }
                    bordered={false}
                    style={{ height: "80px", flex: 1 }}
                    className="centered-card"
                  >
                    {typeof this.state.ABATR === "number"
                      ? this.state.ABATR.toFixed(2) + " €/kCO2"
                      : "N/A"}
                  </Card>
                  <Card
                    title={
                      <>
                        {" "}
                        <CloudTwoTone /> FootPrint
                      </>
                    }
                    bordered={false}
                    style={{ height: "80px", flex: 1 }}
                    className="centered-card"
                  >
                    {typeof this.state.Foot === "number"
                      ? (
                          this.state.Foot *
                          this.state.SystemDelivered *
                          0.001
                        ).toFixed(2) + " kgCO2"
                      : "N/A"}
                  </Card>
                </div>
                <br></br>
                <Table columns={columns} dataSource={dataC} />
                <br></br>
                <Table columns={columnl} dataSource={dataL} />
              </div>
            </div>
          </div>
          <br />
          <div
            style={{
              width: "170vh",
              marginBottom: 10,
              backgroundColor: "#f0f0f0",
              padding: 5,
              border: "solid 1px",
              borderColor: "#cccccc",
            }}
          >
            <Dropdown
              overlay={
                <Menu>
                  <div>
                    {this.state.plotRT.map((itemb) => (
                      <Menu.Item
                        onClick={() => this.handleClickB(itemb)}
                        style={{ minWidth: 0, flex: "auto" }}
                      >
                        {String(itemb)}
                      </Menu.Item>
                    ))}
                  </div>
                </Menu>
              }
              trigger={["click"]}
            >
              <Button
                className="ant-dropdown-link"
                icon={<VerticalAlignBottomOutlined />}
                onClick={(e) => e.preventDefault()}
              >
                {this.state.optimization
                  ? `${this.state.optimization}`
                  : "Plots"}
              </Button>
            </Dropdown>
            {this.state.optimization == "Dynamics" && (
              <Select
                value={this.state.selectedOption}
                style={{ width: 200 }}
                onChange={this.handleChangeDWM}
              >
                <Option value="Dynamics">Hourly</Option>
                <Option value="dailyDyn_">Daily</Option>
                <Option value="weeklyDyn_">Weekly</Option>
                <Option value="monthlyDyn_">Monthly</Option>
              </Select>
            )}
            {this.state.optimization == "Usage" && (
              <Select
                value={this.state.selectedOption2}
                style={{ width: 200 }}
                onChange={this.handleChangeDWMU}
              >
                <Option value="Usage">Daily</Option>
                <Option value="weeklyUsage_">Weekly</Option>
                <Option value="monthlyUsage_">Monthly</Option>
              </Select>
            )}
            <Card>
              {" "}
              <div className="App">
                <canvas
                  ref={this.chartRef}
                  style={{ width: "100%", height: "400px" }}
                />
              </div>
            </Card>
            <Button
              onClick={(e) =>
                this.state.options === "weeklyDyn_" ||
                this.state.options === "monthlyDyn_" ||
                this.state.options === "dailyDyn_" ||
                this.state.options === "weeklyUsage_" ||
                this.state.options === "monthlyUsage_"
                  ? this.handleClickBDWM(this.state.options)
                  : this.handleClickB(this.state.optimization)
              }
            >
              {" "}
              Change color{" "}
            </Button>
            <Button onClick={this.resetZoom}>Reset Zoom</Button>
            <Button
              className="ant-dropdown-link"
              icon={<VerticalAlignBottomOutlined />}
              onClick={(e) =>
                this.DownloadLCSV(this.state.optimization, this.state.options)
              }
            >
              Download CSV
            </Button>
            <Button
              className="ant-dropdown-link"
              icon={<VerticalAlignBottomOutlined />}
              onClick={(e) => this.DownloadPNG(this.state.optimization)}
            >
              Download PNG
            </Button>

            <br></br>
            <br></br>
          </div>
        </Form>
      </>
    );
  }
}

export default OptimizationForm;
